import React from 'react'
import Helmet from 'react-helmet'

import Press from '../components/Press/Press'

const PressIndex = () => (
  <div style={{ background: '#fff' }}>
    <Helmet title={'Ovation | Press'}>
      <meta
        name="description"
        content="Practice your public speaking in VR. Ovation provides realistic virtual audiences, tools and feedback to help you grow your skills."
      />
      <meta name="keywords" content="VR, Public, Speaking, Training" />
    </Helmet>
    <Press />
  </div>
)

export default PressIndex
