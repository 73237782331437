import React from 'react'

import './Press.scss'

export default class Press extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="press-wrapper">
        <div className="press-header">Press</div>
        <div className="press-subtext">
          Inquiries:&nbsp;
          <a href="mailto:press@ovationvr.com">press@ovationvr.com</a>
        </div>

        {/* <div className="press-subheader">Press Kit</div>
        <div className="press-link">
          <a
            target="_blank"
            href="https://s3.amazonaws.com/ovation-vr-press-kit/CompanyInfoAndProgramDescription.pdf"
            className="gtm-download-company-info-and-program-description"
          >
            Company Info and Program Description (PDF)
          </a>
        </div>
        <div className="press-link">
          <a
            href="https://s3.amazonaws.com/ovation-vr-press-kit/DigitalAssets.zip"
            className="gtm-download-press-digital-assets"
          >
            Digital Assets (ZIP)
          </a>
        </div> */}

        <div className="press-subheader">Ovation in the News</div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://garage.ext.hp.com/us/en/innovation/vr-fear-public-speaking.html"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            Hewlett Packard
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.roadtovr.com/ovation-vr-public-speaking-training-simulator/"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            Road To VR
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.newyorker.com/culture/culture-desk/a-virtual-reality-program-to-conquer-the-fear-of-public-speaking"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            The New Yorker
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.telegraph.co.uk/technology/2019/01/09/can-virtual-reality-cure-fear-public-speaking/"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            Telegraph
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.engadget.com/2019/01/08/htc-vive-pro-eye-hands-on-gaze-tracking-ces-2019/"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            Engadget (article & video)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.theverge.com/2019/1/7/18172700/htc-vive-pro-eye-tracking-virtual-reality-headset-features-pricing-release-date-ces-2019"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            The Verge (article & video)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.iheart.com/podcast/1119-this-time-tomorrow-52858437/episode/through-the-virtual-looking-glass-55237112"
            className="gtm-outbound-link-press-ovation-in-the-news"
          >
            This Time Tomorrow (podcast)
          </a>
        </div>

        <div className="press-subheader">Events</div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://atd2021.pathable.co/"
            className="gtm-outbound-link-press-events"
          >
            ATD International Conference & EXPO (2021)
          </a>
        </div>
        <div className="press-link">
          <a target="_blank" rel="noopener" href="https://gdconf.com/" className="gtm-outbound-link-press-events">
            GDC (2021)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://talent-think-tanks.coriniumintelligence.com/"
            className="gtm-outbound-link-press-events"
          >
            Talent Executive Think Tanks (2021)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.techlearnconference.com/2020/"
            className="gtm-outbound-link-press-events"
          >
            TechLearn Conference (2020)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://events.educause.edu/annual-conference/programs-and-tracks/learning-labs"
            className="gtm-outbound-link-press-events"
          >
            EDUCAUSE Annual Conference (2020)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.vrarglobalsummit.com/"
            className="gtm-outbound-link-press-events"
          >
            VR AR Global Summit (2020)
          </a>
        </div>
        <div className="press-link">
          <a>HP Rock On (2019)</a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://clo-apex-fall.coriniumintelligence.com"
            className="gtm-outbound-link-press-events"
          >
            Chief Learning Officer, APEX Fall (2019)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.toastmasters.org/events/2019-international-convention"
            className="gtm-outbound-link-press-events"
          >
            Toastmasters International Convention (2019)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.cta.tech/Events/Event-List/CES-on-the-Hill.aspx"
            className="gtm-outbound-link-press-events"
          >
            CES On the Hill (2019)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://atdconference.td.org/"
            className="gtm-outbound-link-press-events"
          >
            ATD International Conference & EXPO (2019)
          </a>
        </div>
        <div className="press-link">
          <a target="_blank" rel="noopener" href="https://www.gdconf.com/" className="gtm-outbound-link-press-events">
            GDC (2019)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.nvidia.com/en-us/gtc/"
            className="gtm-outbound-link-press-events"
          >
            NVIDIA GTC (2019)
          </a>
        </div>
        <div className="press-link">
          <a target="_blank" rel="noopener" href="https://www.njasa.net/" className="gtm-outbound-link-press-events">
            Techspo (2019)
          </a>
        </div>
        <div className="press-link">
          <a target="_blank" rel="noopener" href="https://www.ces.tech/" className="gtm-outbound-link-press-events">
            CES (2019)
          </a>
        </div>
        <div className="press-link">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.njeaconvention.org/"
            className="gtm-outbound-link-press-events"
          >
            NJEA Convention (2018)
          </a>
        </div>
        <div className="press-link">
          <a target="_blank" rel="noopener" href="https://www.nyvrexpo.com/" className="gtm-outbound-link-press-events">
            NYVR Expo (2018)
          </a>
        </div>
      </div>
    )
  }
}
